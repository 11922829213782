/**
 * External Dependencies
 */

import 'jquery';
import "@lottiefiles/lottie-player";
import Plyr from 'plyr';

// import Flickity from 'flickity/dist/flickity.pkgd';

var $ = require('jquery');
var jQueryBridget = require('jquery-bridget');
var Flickity = require('flickity');

// make Flickity a jQuery plugin
Flickity.setJQuery( $ );
jQueryBridget( 'flickity', Flickity, $ );

$(document).ready(function() {
  // PRICING
  $(function() {
    var tabs = $('.pricing__payment');
    tabs.find('.pricing__payment-toggle').on('click', function() {
      var $this = $(this);

      if ($this.hasClass('active')) return;

      var direction = $this.attr('tab-direction');

      tabs.removeClass('left right').addClass(direction);
      tabs.find('.pricing__payment-toggle.active').removeClass('active');
      $this.addClass('active');

      $('.pricing__yearly__savings.block').toggleClass('block sm:inline hidden').siblings('.pricing__yearly__savings').toggleClass('block sm:inline hidden');

      calculatePrice($('.quantity-field').val());
    });
  });

  $('.pricing__module-info').on('click', function() {
    var module = $(this).closest('.pricing__module').data('id');
    var $popup = $('.pop-up-module');
    var content = $(`.popup_content[data-id='${module}']`);
    $popup.addClass('active')

    $popup.find('.popup__image').parent().addClass('!hidden')
    $popup.find('.popup__button').addClass('hidden')

    if(content.data('popup-image') && content.data('popup-image').length) {
      $popup.find('.popup__image').attr('src', content.data('popup-image')).parent().removeClass('!hidden')
    }

    $popup.find('.popup__subtitle').html(content.data('popup-subtitle'))
    $popup.find('.popup__title').html(content.data('popup-title'))
    $popup.find('.popup__content').html(content.html())

    var button = content.data('popup-button');
    if(button && button.url && button.title) {
      $popup.find('.popup__button').removeClass('hidden')
      .attr('href', button.url)
      .attr('target', button.target)
      .find('span').text(button.title)
    }

    $('body').addClass('overflow-hidden');
  })

  $('.btn-offerte').on('click', function() {
    $('.pop-up-quota').addClass('active');
    $('body').addClass('overflow-hidden');
  })

$('.choose-btn').on('click', function(e) {
  const id = $(this).data('identifier');

  $(`.package[data-identifier="${id}"]`).addClass('active').siblings('.package').removeClass('active')

  $(this).addClass('active-ghost').find('span').addClass('active-ghost')
  $(`.choose-btn[data-identifier="${id}"]`).parents('.pricing__heading').siblings().find('.choose-btn').removeClass('active-ghost').find('span').removeClass('active-ghost')

  $(`.pricing_check[data-identifier="${id}"]`).removeClass('opacity-50')
  $(`.pricing_check[data-identifier="${id}"]`).siblings('div[data-identifier]').addClass('opacity-50')

  if(id === 0) {
    $('.selection').css({left: '25%' })
  } else if(id === 1) {
    $('.selection').css({left: '50%' })
  } else {
    $('.selection').css({left: '75%' })
  }

  $(`.select-check[data-identifier="${id}"]`)
  .addClass('!scale-100').removeClass('scale-0')
  .parent('.package').siblings().find('.select-check')
  .removeClass('!scale-100').addClass('scale-0')

})

// Start experiment 555.0

$(window).on('scroll load', function() {
  $('.pricing__heading').each(function () {
    if ($(window).scrollTop() >= $(this).offset().top) {
      $(this).addClass('bg-primary')
    } else {
      $(this).removeClass('bg-primary')
    }
  });
});

// End experiment

  $('.pop-up__bg').on('click', function() {
    $('.pop-up').removeClass('active');
    $('body').removeClass('overflow-hidden');
  })

  $('.pop-up__close').on('click', function() {
    $('.pop-up').removeClass('active');
    $('body').removeClass('overflow-hidden');
  })

  $('.pricing__module input[type="checkbox"]').on('click', function(){
    if($(this).prop('checked') == true) {
      $(this).closest('.pricing__module').addClass('active');
      if($(this).parents('.pricing__module').data('id') == 'app') {
        $('.pricing__oneoff__costs').removeClass('opacity-0')
      }
    }
    else if($(this).prop('checked') == false) {
      $(this).closest('.pricing__module').removeClass('active');
      if($(this).parents('.pricing__module').data('id') == 'app') {
        $('.pricing__oneoff__costs').addClass('opacity-0')
      }
    }
  });

  $('.pricing__module-toggle').on('click', function() {
    calculatePrice($('.quantity-field').val());
  });

  $('.quantity-field').on('focusout', function(e) {
    if (isNaN(e.target.value) || e.target.value < 5) {
      $(this).val(5);
      calculatePrice(5);
      return;
    }

    calculatePrice(e.target.value);
  });

  function getActiveModules(type = 1) {
    var priceLite = 0;
    var priceStandard = 0;
    var pricePremium = 0;
    $('.pricing__module[data-id].active').each(function() {
      priceLite     += $(this).data('module-price-lite');
      priceStandard += $(this).data('module-price-standard');
      pricePremium  += $(this).data('module-price-premium');
    });


    if(type == 0) { return priceLite; }
    else if(type == 1) { return priceStandard; }
    else if(type == 2) { return pricePremium;}
  }

  // INPUT USERS
  function incrementValue(e) {
    e.preventDefault();
    var fieldName = $(e.target).data('field');
    var parent = $(e.target).closest('div');
    var currentVal = parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);


    if (!isNaN(currentVal)) {
      parent.find('input[name=' + fieldName + ']').val(currentVal + 1);
      calculatePrice(currentVal + 1);
    } else {
      parent.find('input[name=' + fieldName + ']').val(5);
    }

  }

  function decrementValue(e) {
    e.preventDefault();
    var fieldName = $(e.target).data('field');
    var parent = $(e.target).closest('div');
    var currentVal = parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);

    if (!isNaN(currentVal) && currentVal > 5) {
      parent.find('input[name=' + fieldName + ']').val(currentVal - 1);
      calculatePrice(currentVal - 1);
    } else {
      parent.find('input[name=' + fieldName + ']').val(5);
    }

  }

  $('.pricing__users').on('click', '.button-plus', function(e) {
    incrementValue(e);
  });

  $('.pricing__users').on('click', '.button-minus', function(e) {
    decrementValue(e);
  });

  function calculatePrice(users) {
    $('.pricing__users_count').text(users);

    $('.pricing__heading[data-package-id]').each(function() {
      var packageId       = $(this).data('package-id');
      // var packagePrice    = $(this).data('package-price');
      var packageFormula  = $(this).data('package-formula');
      var typeMonthly     = $('.pricing__payment-toggle.active').data('id');
      var total           = 0;
      var totalYearly     = 0;
      var modulePrice     = getActiveModules(packageId);
      var pricePerUser    = 50;

      var personalisedAppMonthly = 70;

      if(packageId == 2) {
        // total = packagePrice + ((users -  4) * packageFormula);
        total = (users -  4) * packageFormula;
        pricePerUser = 75;
      } else {
        // total = packagePrice + ((users -  5) * packageFormula);
        total = (users -  5) * packageFormula;
      }

      // if(modulePrice > 0) {
      //   total += moduleMonthly;
      // }

      total += modulePrice;

      totalYearly = total;

      if(typeMonthly == 2) {
        total = total / 12 * 11;
      }

      $(`.pricing__heading[data-package-id="${packageId}"]`).find('.pricing__yearly__savings__count').text((Math.round(totalYearly * 100)/100).toFixed(2).replace(".", ","));
      $(`.pricing__heading[data-package-id="${packageId}"]`).find('.price').text((Math.round(total * 100)/100).toFixed(2).replace(".", ","));
      $(`.pricing__heading[data-package-id="${packageId}"]`).find('.pricing__users_price').text((Math.round(((users - 5) * pricePerUser) * 100)/100).toFixed(2).replace(".", ","));
    })
  }

  calculatePrice(5);

  function getQuota() {
    var chosenModules = [];
    var packageTitle = $('.package.active .package__title').text();
    var users = $('.quantity-field').val();
    var payment = $('.pricing__payment .active span').text();
    var activePackage = $('.package.active').data('package-id');
    var price = $(`.pricing__heading[data-package-id="${activePackage}"]`).find('.price').text();

    $('.pricing__module.active').each(function() {
      var title =  $(this).find('.pricing__module__title').text()
      var index = chosenModules.indexOf(title);
      if (index === -1) {
        chosenModules.push(title);
      } else {
        chosenModules.splice(index, 1);
      }
    });

    return {
      package : packageTitle,
      modules : chosenModules.toString(),
      users   : users,
      payment : payment,
      price   : price,
    }
  }

  $('.quota__button').on('click', function() {
    var quota = getQuota();
    quota.code = $(this).data('code');

    $('#field_4_3 input[name="input_3"]').val(quota.package);
    $('#field_4_1 input[name="input_1"]').val(quota.modules);
    $('#field_4_5 input[name="input_5"]').val(quota.code);
    $('#field_4_7 input[name="input_7"]').val(quota.users);
    $('#field_4_8 input[name="input_8"]').val(quota.payment);
    $('#field_4_9 input[name="input_9"]').val(quota.price);
  });

  // MUTED VIDEO
  $('video').prop('muted', true);
  $('.mute-video').click(function () {
    if ($('video').prop('muted')) {
      $('video').prop('muted', false);
      $(this).addClass('unmute-video');

    } else {
      $('video').prop('muted', true);
      $(this).removeClass('unmute-video');
    }
  });

  // MOB SLIDER
  if($('.mob-slider').length) {
    var $carousel = $('.mob-slider').flickity({
      watchCSS: true,
      pageDots: true,
      prevNextButtons: false,
      wrapAround: false,
      cellAlign: 'left',
      draggable: true,
    });
  };

  // SCROLLED NAV
  $(window).on('load scroll', function() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('scrolled');
    }
    else {
      $('body').removeClass('scrolled');
    }
  });

  // MENU
  $('.hamburger').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.close-nav').click(function() {
    $('.hamburger').toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });


  // active images in viewport
  var active_images = 8;

  // time image change
  var fader_delay   = 1500;

  // setup image urls
  var images = [];

  // read img from html
  $('#logofader img').each(function(index) {

    // add src to array
    images.push($( this ).attr('src'))

    // remove from dom
    $( this ).remove();
  });

  // add initial active images to active array
  var images_active = images.splice(0, active_images);

  // add initial images to viewport
  for (var i = 0; i < images_active.length; i++) {
    $('#logofader').prepend($(`
      <div class="brand w-full relative ">
          <div class="aspect-h-12 aspect-w-[20]"></div>
          <div class="ease-in-out duration-300 absolute inset-0 flex items-center justify-center border border-white/10 rounded-3xl hover:border-white/30">
            <div class="p-2">
              <img class="brand__image w-full h-auto object-contain z-10 relative max-w-[10rem]" src="${images_active[i]}">
            </div>
          </div>
        </div>
    `));
  }

  var loop = runLoop();

  function runLoop() {
    return window.setInterval(function(){
      // get random active image from array
      var randomActive = images_active[Math.floor(Math.random()*images_active.length)];

      // get random hidden image from array
      var randomHidden = images[Math.floor(Math.random()*images.length)];


      // loop trough active images
      $('#logofader img').each(function(index) {

        // find the random image
        if($( this ).attr('src') == randomActive) {

          // replace the src with the new url
          $(this).fadeOut(400, function() {
              $( this ).attr('src', randomHidden);
          })
          .fadeIn(400);
          // $( this ).attr('src', randomHidden);

        }
      });

      // call swapimage for keeping arrays up to date
      swapImage(randomActive, randomHidden);
    }, fader_delay);
  }


  function swapImage(urlActive, urlHidden) {


    // find active url and remove it from array
    images_active = images_active.filter((element) => {
      if(element != urlActive)
        return true
      else
        return false
    });

    // find hidden url and remove it from array
    images = images.filter((element) => {
      if(element != urlHidden)
        return true
      else
        return false
    });

    // add urls to arrays
    images.push(urlActive);
    images_active.push(urlHidden);

  }

  window.addEventListener('focus', function() {
    // browser active do loop
    loop = runLoop();
  },false);

  window.addEventListener('blur', function() {
      // browser gone stop loop
      window.clearInterval(loop);
  },false);

  // PLYR
  const players = Array.from(document.querySelectorAll('.plyr__video-embed'));

  players.map(player => new Plyr(player, {
    ratio: '16:9',
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
    autopause: false,
    muted: false,
  }));

  // ACCORDEON
  $(function() {
    $('.accordeon > .accordeon-item.is-active').children('.accordeon-panel').slideDown();
    $('.accordeon > .accordeon-item').click(function() {
      $(this).siblings('.accordeon-item').removeClass('is-active').children('.accordeon-panel').slideUp();
      $(this).toggleClass('is-active').children('.accordeon-panel').slideToggle('ease-out');
      var $selectedTab = $(this).attr('data-item');
      $('.accordeon-image').removeClass('active');
      $('.accordeon-images').find(`.accordeon-image[data-item='${$selectedTab}']`).addClass('active');
    });
  });

  // TABNAV
  if($('.js-tabnav-tab').length) {
    (function($) {
      $.fn.tabnav = function tabnav(userConfig) {
        var config = $.extend({
          activeTabClass: 'active',
          activePanelClass: 'active',
        }, userConfig);

        var $wrapper = $(this);
        var $tabs = $wrapper.find('.js-tabnav-tab');
        var $panels = $wrapper.find('.js-tabnav-panel');
        var $panelsWrapper = $wrapper.find('.js-tabnav-panels');
        var $activeTab;
        var $activePanel;
        var activeTab = 0;

        $panels
        .attr('role', 'tabpanel')
        .attr('aria-hidden', 'true');

        $tabs
        .attr('role', 'tab')
        .attr('aria-selected', 'false')
        .attr('aria-setsize', $tabs.length)
        .attr('tabindex', 0)
        .each(function(i, e) {
          var $elem = $(e);
          if ($elem.hasClass(config.activeTabClass)) {
            setActiveTab(i);
          }
          $elem.attr('aria-posinset', i + 1);
        })
        .click(setTabs)
        .keypress(setTabs);

        function setActiveTab(tabPos) {
          $activeTab = $($tabs.get(tabPos))
          .addClass(config.activeTabClass)
          .attr('aria-selected', 'true');

          $activePanel = $($panels.get(tabPos))
          .addClass(config.activePanelClass)
          .attr('aria-hidden', 'false');

          $panelsWrapper.css('height', $activePanel.height() + 'px');
        }

        function setTabs(e) {
          if (e.type === 'keypress' && e.keyCode !== 13) {
            return;
          }

          var $elem = $(e.currentTarget);

          var tabPos = Number($elem.attr('aria-posinset')) - 1;
          $tabs
          .removeClass(config.activeTabClass)
          .attr('aria-selected', 'false');
          $panels
          .removeClass(config.activePanelClass)
          .attr('aria-hidden', 'true');

          setActiveTab(tabPos);
        }

        $(window).resize(function onWindowResize() {
          $panelsWrapper.css('height', $activePanel.height() + 'px');
        });

        $(window).load(function() {
          $panelsWrapper.css('height', $activePanel.height() + 'px');
        });
      }

      var tabnav = $('.tabnav');
      if (tabnav.length != 0) {
        $(tabnav).each(function(){
          $(this).tabnav();
        });
      }
    }(jQuery));
  }

  // NEWS FILTER
  $('.news-checkbox').change(handleNewsFilter);

  function handleNewsFilter () {

    var uristring = '?category=';

    $('.news-checkbox:checked').each(function(index) {
      var isLastElement = index == $('.news-checkbox:checked').length -1;

      uristring += $(this).val();

      if(!isLastElement) {
        uristring += ',';
      }

    });

    window.location.href = uristring;
  }

  // CASES FILTER
  $('.cases-checkbox').change(handleCatFilter);

  function handleCatFilter () {

    var uristring = '?category=';

    $('.cases-checkbox:checked').each(function(index) {
      var isLastElement = index == $('.cases-checkbox:checked').length -1;

      uristring += $(this).val();

      if(!isLastElement) {
        uristring += ',';
      }

    });

    window.location.href = uristring;
  }

  // CHECK ACTIVE
  var lastId,
    topMenu = $('.functions-menu'),
    topMenuHeight = topMenu.outerHeight() + 150,

    // All list items
    menuItems = topMenu.find('a'),
    // Anchors corresponding to menu items
    scrollItems = menuItems.map(function(){
      var item = $($(this).attr('href'));
      if (item.length) { return item; }
    });

    if ($(window).width() < 1024) {
      topMenuHeight = topMenu.outerHeight() + 80;
    }

    // Video Block
    $('.play-btn').on('click', function() {
      $(this).siblings('.overlay, .placeholder').addClass('opacity-0');
      $('.overlay').siblings('.play-btn').addClass('opacity-0');
      $(this).siblings('.video').trigger('play');
      $(this).closest('.newsitem').addClass('playing-vid');
      $(this).parents('.newsitem').siblings('.newsitem').addClass('hidden');
      $('video').prop('muted', false);
      $('.video-controls').removeClass('opacity-0');
    });


    $(".mute-btn").on('click', function () {
      if ($("video").prop('muted')) {
          $("video").prop('muted', false);
          $(".mute-btn").html(`
            <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-4 h-4'>
              <path stroke-linecap='round' stroke-linejoin='round' d='M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z' />
            </svg>
          `);
      } else {
          $("video").prop('muted', true);
          $(".mute-btn").html(`
            <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-4 h-4'>
              <path stroke-linecap='round' stroke-linejoin='round' d='M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z' />
            </svg>
          `);
      }
    });
    
    $(".stop-btn").click(function () {
      $('video').trigger('pause');
      $('.video-controls').parents('.overlay').removeClass('opacity-0');
      $('.overlay').siblings('.play-btn').removeClass('opacity-0');
      $(this).closest('.newsitem').removeClass('!col-span-2 playing-vid');
      $(this).parents('.newsitem').siblings('.newsitem').removeClass('hidden');
      $('.video-controls').addClass('opacity-0');
    });

    // Bind click handler to menu items
    // so we can get a fancy scroll animation
    menuItems.click(function(e){
      var active = jQuery('.functions-menu__wrapper').find('.active');
      var href = $(this).attr('href'),
      offsetTop = href === '#' ? 0 : $(href).offset().top - topMenuHeight;
      $('html, body').stop().animate({
        scrollTop: offsetTop,
      }, 300);
      $(this).addClass('active');
      e.preventDefault();
    });

    $(window).scroll(function(){
      var active = jQuery('.functions-menu__wrapper').find('.active');

      if( active.length ) {
        $('.functions-menu').addClass('bg-primary/80 backdrop-blur-2xl');
      }
      else {
        $('.functions-menu').removeClass('bg-primary/80 backdrop-blur-2xl');
      }

      // if ($(window).width() < 1024) {
      //   if( active.length ) {
      //       var positionLeft = active.position().left;
      //       $('.functions-menu__wrapper').css('left', - positionLeft);
      //   }
      // }
      // Get container scroll position
      var fromTop = $(this).scrollTop()+topMenuHeight + 1;

      // Get id of current scroll item
      var cur = scrollItems.map(function(){
        if ($(this).offset().top < fromTop)
        return this;
      });

      // Get the id of the current element
      cur = cur[cur.length-1];
      var id = cur && cur.length ? cur[0].id : '';

      if (lastId !== id) {
        lastId = id;
        // Set/remove active class
        menuItems
        .parent().removeClass('active')
        .end().filter('[href="#'+id+'"]').parent().addClass('active');
      }
    });

    // Share button news/blog

    $('.share-btn').on('click', function() {
      $('.social-menu').slideToggle();
    })

    // Menu Sidebar Release notes
    var navbarHeight = 130;

    $('#sidebarMenu a').on('click', function(e) {
      e.preventDefault();
      var target = $(this.hash);
      $('html, body').animate({
        scrollTop: target.offset().top - navbarHeight
      }, 500, function() {
        $('#sidebarMenu a').addClass('opacity-20');
        $(e.currentTarget).removeClass('opacity-20');
      });
    });

    $(window).on('scroll', function() {
      var scrollPos = $(document).scrollTop();
      var closestLink = null;
      var closestLinkDist = Infinity;
    
      $('#sidebarMenu a').each(function() {
        var currLink = $(this);
        var refElement = $(currLink.attr('href'));
        var refElementTop = refElement.position().top;
    
        var dist = scrollPos - refElementTop;
    
        if(dist > 0 && dist < closestLinkDist) {
          closestLinkDist = dist;
          closestLink = currLink;
        }
      });
    
      $('#sidebarMenu a').addClass('opacity-20');
    
      if(closestLink !== null) {
        closestLink.removeClass('opacity-20');
      }
    });
    

    // Type animation

    const words = document.querySelectorAll('.word');
    let currentIndex = 0;
    let roundCount = 0;
  
    // Initialize the first word
    words[currentIndex].style.opacity = '1';
    words[currentIndex].style.transform = 'translateY(0)';
  
    function showNextWord() {
      const previousIndex = currentIndex;
      currentIndex = (currentIndex + 1) % words.length;
  
      resetWordStyles(previousIndex);
      resetWordStyles(currentIndex);
  
      // Trigger reflow by accessing offsetHeight
      words[currentIndex].offsetHeight;
  
      words[previousIndex].style.opacity = '0';
      words[previousIndex].style.transform = 'translateY(-100%)';
      words[currentIndex].style.opacity = '1';
      words[currentIndex].style.transform = 'translateY(0)';
  
      if (currentIndex === 0) {
        roundCount++;
        if (roundCount === 1) {
          resetAllWordStyles();
          roundCount = 0;
        }
      }
    }
  
    function resetWordStyles(index) {
      words[index].style.opacity = '0';
      words[index].style.transform = 'translateY(100%)';
    }
  
    function resetAllWordStyles() {
      words.forEach((word) => {
        word.style.opacity = '';
        word.style.transform = '';
      });
    }
    setInterval(showNextWord, 2500);
});
